import { template as template_0ba8904093c14932acd2659d9ca3c35b } from "@ember/template-compiler";
const FKControlMenuContainer = template_0ba8904093c14932acd2659d9ca3c35b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
