import { template as template_32fcda2c23464c87896636c8e1a694f5 } from "@ember/template-compiler";
const FKLabel = template_32fcda2c23464c87896636c8e1a694f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
