import { template as template_45325fd75387472b8bf754884640d478 } from "@ember/template-compiler";
const SidebarSectionMessage = template_45325fd75387472b8bf754884640d478(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
